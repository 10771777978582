export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyD5J98YkA-aJD1d3JvdFfEUbm6ihZbjWyc",
    authDomain: "feedback-ed5aa.firebaseapp.com",
    databaseURL: "https://feedback-ed5aa.firebaseio.com",
    projectId: "feedback-ed5aa",
    storageBucket: "feedback-ed5aa.appspot.com",
    messagingSenderId: "892380943411"
  }
};
